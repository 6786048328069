import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'

import s from './styles.module.css'

const WholesaleSidebar = ({ index, className }) => {
  return (
    <div
      className={classNames(s.container, className, {
        [s.containerNotIndex]: !index,
      })}
    >
      <h1 className={s.title}>Chalk Wholesale Account Application</h1>
      {/* <p className={s.description}>
        Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
        Curabitur blandit tempus porttitor. Maecenas sed diam eget risus varius
        blandit sit amet non magna.
      </p> */}
      <StaticImage
        src="../../assets/wholesale.jpg"
        className={s.img}
        alt="wholesale enquiries"
        placeholder="NONE"
        backgroundColor="#eee"
        style={{ position: !index ? 'absolute' : '' }}
      />
    </div>
  )
}

WholesaleSidebar.propTypes = {
  index: PropTypes.bool,
  className: PropTypes.string,
}
WholesaleSidebar.defaultProps = {
  index: false,
  className: '',
}

export default WholesaleSidebar
