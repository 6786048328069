import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import s from './styles.module.css'

function Loading({ className }) {
  return (
    <div className={classNames(s.container, className)}>
      <div className={s.loading} />
    </div>
  )
}

Loading.propTypes = {
  className: PropTypes.string,
}
Loading.defaultProps = {
  className: '',
}

export default Loading
