import React, { useState, useRef, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import classNames from 'classnames'
import validate from 'validate.js'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import WholesaleSidebar from 'components/WholesaleSidebar'
import Overlay from 'components/Overlay'
import Loading from 'components/Loading'

import s from './start.module.css'

export default function ApplicationStart() {
  const [overlay, setOverlay] = useState(null)
  const [sending, setSending] = useState(false)
  const [formError, setFormError] = useState(null)

  const sections = [
    {
      title: 'Your Details',
      fields: [
        {
          attr: 'yourName',
          placeholder: 'Name',
          constraints: {
            length: {
              minimum: 2,
              tooShort: 'needs to have 2 parts',
              tokenizer: (value) => value.split(/\s+/g),
            },
          },
        },
        {
          attr: 'phoneNumber',
          label: 'Best contact number?',
          type: 'tel',
          constraints: {
            numericality: true,
          },
        },
        {
          attr: 'email',
          placeholder: 'email@domain.com',
          label: 'Your email address',
          constraints: {
            email: true,
          },
        },
      ],
    },
    {
      title: 'Store Details',
      fields: [
        {
          attr: 'storeType',
          label: 'How do you sell to your customers?',
          select: ['Online Store', 'Retail only', 'Online & Retail'],
          initialValue: 'Online Store',
        },
        {
          attr: 'storeName',
          label: 'What is the name of your store?',
        },
        {
          attr: 'website',
          label: 'Do you have a website?',
          placeholder: 'Please add your URL',
          optional: true,
        },
        {
          attr: 'instagram',
          label: 'Are you on Instragram?',
          placeholder: 'Instagram handle',
          placeholderPrefix: '@',
          optional: true,
        },
      ],
    },
    {
      title: 'Store Address',
      fields: [
        {
          attr: 'address',
          placeholder: 'Address line 1',
        },
        {
          attr: 'address2',
          label: 'Address',
          placeholder: 'Address line 2',
          hideLabel: true,
          optional: true,
        },
        {
          attr: 'city',
          hideLabel: true,
        },
        {
          attr: 'county',
          hideLabel: true,
          optional: true,
        },
        {
          attr: 'postcode',
          hideLabel: true,
        },
        {
          attr: 'country',
          hideLabel: true,
          select: [
            'United Kingdom',
            'Ireland',
            'Afghanistan',
            'Albania',
            'Algeria',
            'American Samoa',
            'Andorra',
            'Angola',
            'Anguilla',
            'Antigua & Barbuda',
            'Argentina',
            'Armenia',
            'Aruba',
            'Australia',
            'Austria',
            'Azerbaijan',
            'Bahamas',
            'Bahrain',
            'Bangladesh',
            'Barbados',
            'Belarus',
            'Belgium',
            'Belize',
            'Benin',
            'Bermuda',
            'Bhutan',
            'Bolivia',
            'Bonaire',
            'Bosnia & Herzegovina',
            'Botswana',
            'Brazil',
            'British Indian Ocean Ter',
            'Brunei',
            'Bulgaria',
            'Burkina Faso',
            'Burundi',
            'Cambodia',
            'Cameroon',
            'Canada',
            'Canary Islands',
            'Cape Verde',
            'Cayman Islands',
            'Central African Republic',
            'Chad',
            'Channel Islands',
            'Chile',
            'China',
            'Christmas Island',
            'Cocos Island',
            'Colombia',
            'Comoros',
            'Congo',
            'Cook Islands',
            'Costa Rica',
            'Cote DIvoire',
            'Croatia',
            'Cuba',
            'Curacao',
            'Cyprus',
            'Czech Republic',
            'Denmark',
            'Djibouti',
            'Dominica',
            'Dominican Republic',
            'East Timor',
            'Ecuador',
            'Egypt',
            'El Salvador',
            'Equatorial Guinea',
            'Eritrea',
            'Estonia',
            'Ethiopia',
            'Falkland Islands',
            'Faroe Islands',
            'Fiji',
            'Finland',
            'France',
            'French Guiana',
            'French Polynesia',
            'French Southern Ter',
            'Gabon',
            'Gambia',
            'Georgia',
            'Germany',
            'Ghana',
            'Gibraltar',
            'Great Britain',
            'Greece',
            'Greenland',
            'Grenada',
            'Guadeloupe',
            'Guam',
            'Guatemala',
            'Guinea',
            'Guyana',
            'Haiti',
            'Hawaii',
            'Honduras',
            'Hong Kong',
            'Hungary',
            'Iceland',
            'Indonesia',
            'India',
            'Iran',
            'Iraq',
            'Isle of Man',
            'Israel',
            'Italy',
            'Jamaica',
            'Japan',
            'Jordan',
            'Kazakhstan',
            'Kenya',
            'Kiribati',
            'Korea North',
            'Korea South',
            'Kuwait',
            'Kyrgyzstan',
            'Laos',
            'Latvia',
            'Lebanon',
            'Lesotho',
            'Liberia',
            'Libya',
            'Liechtenstein',
            'Lithuania',
            'Luxembourg',
            'Macau',
            'Macedonia',
            'Madagascar',
            'Malaysia',
            'Malawi',
            'Maldives',
            'Mali',
            'Malta',
            'Marshall Islands',
            'Martinique',
            'Mauritania',
            'Mauritius',
            'Mayotte',
            'Mexico',
            'Midway Islands',
            'Moldova',
            'Monaco',
            'Mongolia',
            'Montserrat',
            'Morocco',
            'Mozambique',
            'Myanmar',
            'Nambia',
            'Nauru',
            'Nepal',
            'Netherland Antilles',
            'Netherlands (Holland, Europe)',
            'Nevis',
            'New Caledonia',
            'New Zealand',
            'Nicaragua',
            'Niger',
            'Nigeria',
            'Niue',
            'Norfolk Island',
            'Norway',
            'Oman',
            'Pakistan',
            'Palau Island',
            'Palestine',
            'Panama',
            'Papua New Guinea',
            'Paraguay',
            'Peru',
            'Philippines',
            'Pitcairn Island',
            'Poland',
            'Portugal',
            'Puerto Rico',
            'Qatar',
            'Republic of Montenegro',
            'Republic of Serbia',
            'Reunion',
            'Romania',
            'Russia',
            'Rwanda',
            'St Barthelemy',
            'St Eustatius',
            'St Helena',
            'St Kitts-Nevis',
            'St Lucia',
            'St Maarten',
            'St Pierre & Miquelon',
            'St Vincent & Grenadines',
            'Saipan',
            'Samoa',
            'Samoa American',
            'San Marino',
            'Sao Tome & Principe',
            'Saudi Arabia',
            'Senegal',
            'Seychelles',
            'Sierra Leone',
            'Singapore',
            'Slovakia',
            'Slovenia',
            'Solomon Islands',
            'Somalia',
            'South Africa',
            'Spain',
            'Sri Lanka',
            'Sudan',
            'Suriname',
            'Swaziland',
            'Sweden',
            'Switzerland',
            'Syria',
            'Tahiti',
            'Taiwan',
            'Tajikistan',
            'Tanzania',
            'Thailand',
            'Togo',
            'Tokelau',
            'Tonga',
            'Trinidad & Tobago',
            'Tunisia',
            'Turkey',
            'Turkmenistan',
            'Turks & Caicos Is',
            'Tuvalu',
            'Uganda',
            'Ukraine',
            'United Arab Emirates',
            'United States of America',
            'Uruguay',
            'Uzbekistan',
            'Vanuatu',
            'Vatican City State',
            'Venezuela',
            'Vietnam',
            'Virgin Islands (Brit)',
            'Virgin Islands (USA)',
            'Wake Island',
            'Wallis & Futana Is',
            'Yemen',
            'Zaire',
            'Zambia',
            'Zimbabwe',
          ],
          initialValue: 'United Kingdom',
        },
      ],
    },
  ]
  const [activeSection, setActiveSection] = useState(0)

  const initialValues = { marketing: false, terms: false }
  sections.forEach((s) =>
    s.fields.forEach(
      (f) =>
        (initialValues[typeof f === 'string' ? f : f.attr] =
          f.initialValue || '')
    )
  )
  const [values, setValues] = useState(initialValues)
  const [activeField, setActiveField] = useState(null)
  const [activeFieldValue, setActiveFieldValue] = useState(null)
  const [activeFieldError, setActiveFieldError] = useState(null)
  const activeFieldRef = useRef(null)

  useEffect(() => {
    if (activeFieldRef.current) activeFieldRef.current.focus()
  }, [activeField, activeFieldRef])

  const validateField = (field, val) => {
    const attr = field.attr || field
    return validate(
      { [attr]: val },
      {
        [attr]: {
          ...field.constraints,
          presence: field.optional ? false : { allowEmpty: false },
        },
      }
    )
  }

  const handleChange = (value) => {
    setActiveFieldValue(value)
  }

  const activateAnyInvalidField = (fields) => {
    const invalidFields = fields
      .map((f) => validateField(f, values[f.attr || f]))
      .filter((f) => f)

    if (invalidFields.length === 0) return false // all fields are valid

    const firstInvalidFieldAttr = Object.keys(invalidFields[0])[0]
    const firstInvalidField = sections[activeSection].fields.find((f) =>
      f.attr ? f.attr === firstInvalidFieldAttr : f === firstInvalidFieldAttr
    )
    setActiveFieldValue(values[firstInvalidFieldAttr] || '')
    setActiveField(firstInvalidField)
  }

  const handleSelectActiveField = (field) => {
    const attr = field.attr || field
    const currentFieldIndex = sections[activeSection].fields.findIndex((f) =>
      f.attr ? f.attr === attr : f === attr
    )
    const previousFields =
      currentFieldIndex > 0
        ? sections[activeSection].fields.slice(0, currentFieldIndex)
        : []

    if (activateAnyInvalidField(previousFields) === false) {
      setActiveFieldValue(values[attr] || '')
      setActiveField(field)
    }

    setFormError(null)
  }

  const handleCancelActiveField = () => {
    setActiveField(null)
    setActiveFieldValue(null)
  }

  const handleAcceptActiveField = () => {
    // if (!activeFieldValue || activeFieldValue === "") return;
    const currentAttr = activeField.attr || activeField

    const validationResult = validateField(activeField, activeFieldValue)

    if (validationResult && validationResult[currentAttr]) {
      setActiveFieldError(validationResult[currentAttr][0])
      if (activeFieldRef.current) activeFieldRef.current.focus()
      return
    }

    setActiveFieldError(null)
    setValues((cur) => ({
      ...cur,
      [currentAttr]: activeFieldValue,
    }))

    const fieldIndex = sections[activeSection].fields.findIndex((f) =>
      f.attr ? currentAttr === f.attr : currentAttr === f
    )
    if (fieldIndex < sections[activeSection].fields.length - 1) {
      let newActiveField = sections[activeSection].fields[fieldIndex + 1]
      setActiveFieldValue(
        values[newActiveField.attr ? newActiveField.attr : newActiveField] || ''
      )
      setActiveField(newActiveField)
      if (activeFieldRef.current) activeFieldRef.current.focus()
    } else {
      if (activeSection + 1 < sections.length)
        setActiveSection(activeSection + 1)
      setActiveFieldValue(null)
      setActiveField(null)
    }
  }

  const tryGoToNextSection = () => {
    if (activateAnyInvalidField(sections[activeSection].fields) === false)
      setActiveSection(activeSection + 1)
  }

  const handleMultipleStoreAlert = () => {
    setOverlay(
      'Additional store locations can be added when we review your application'
    )
  }

  const handleCloseButtonClick = () => {
    setOverlay({
      confirm:
        'Are you sure you want to close the application, all your details will be lost?',
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!values.address) return setFormError('Please enter all address details')

    if (!values.postcode) return setFormError('Please enter a postcode')

    if (!values.terms)
      return setFormError('Please read & accept the terms of use')

    setSending(true)

    fetch(
      'https://jaley8ott2.execute-api.eu-west-2.amazonaws.com/wholesaleApplication',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.message.startsWith('Contact successfully created')) {
          navigate('/wholesale/application/complete')
        } else {
          setFormError(resp.message)
          setSending(false)
        }
      })
      .catch((error) => {
        setFormError(error.message)
        setSending(false)
      })
  }

  const activeFieldAttr =
    typeof activeField === 'string'
      ? activeField
      : activeField && activeField.attr
  const activeFieldLabel = activeField
    ? activeField.label ||
      activeFieldAttr
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase())
    : null

  return (
    <Layout header="wholesale">
      <SEO
        title="Wholesale Account Sign Up Process | Chalk UK"
        description="Wholesale Sign Up Process. Become a stockist of Chalk's products."
      />
      <div className={s.container}>
        <WholesaleSidebar />
        <form
          name="wholesale-application"
          className={s.formContainer}
          onSubmit={handleSubmit}
          action="/wholesale/application/complete"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="wholesale-application" />
          {Object.keys(values).map((attr) => (
            <input key={attr} type="hidden" name={attr} value={values[attr]} />
          ))}
          <h1 className={s.title}>{sections[activeSection].title}</h1>
          {sections[activeSection].fields.map((f) => {
            const attr = typeof f === 'string' ? f : f.attr
            const label =
              f.label ||
              attr
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, (str) => str.toUpperCase())
            return (
              <div key={attr} className={s.field}>
                {f.hideLabel !== true && (
                  <button
                    type="button"
                    className={s.label}
                    onClick={() => handleSelectActiveField(f)}
                  >
                    {label}
                  </button>
                )}
                <div className={s.fieldInput}>
                  {f.placeholderPrefix && (
                    <div className={s.placeholderPrefix}>
                      {f.placeholderPrefix}
                    </div>
                  )}
                  <button
                    type="button"
                    className={classNames(s.input, {
                      [s.inputWithPrefix]: f.placeholderPrefix,
                    })}
                    onClick={() => handleSelectActiveField(f)}
                  >
                    {values[attr] === ''
                      ? f.placeholder ||
                        attr
                          .replace(/([A-Z])/g, ' $1')
                          .replace(/^./, (str) => str.toUpperCase())
                      : values[attr]}
                  </button>
                  {f.select && (
                    <svg
                      width="9"
                      height="14"
                      viewBox="0 0 9 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={s.selectIcon}
                    >
                      <path
                        d="M4.358 0l3.295 5.25h-6.59L4.358 0zM4.358 14L1.064 8.75h6.589L4.358 14z"
                        fill="#000"
                      />
                    </svg>
                  )}
                </div>
              </div>
            )
          })}
          <div className={s.buttons}>
            {activeSection > 0 && (
              <button
                type="button"
                className={s.button}
                onClick={() => setActiveSection(activeSection - 1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="16"
                  viewBox="22.5 11.5 15 16"
                >
                  <path
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M37 12l-14 7.5L37 27"
                  />
                </svg>
              </button>
            )}
            {activeSection === sections.length - 1 ? (
              <button
                type="button"
                className={s.button}
                onClick={handleMultipleStoreAlert}
              >
                Looking to stock at multiple stores?
              </button>
            ) : (
              <button
                type="button"
                className={s.button}
                onClick={tryGoToNextSection}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.167"
                  height="9.66"
                  viewBox="24.309 14.508 18.167 9.66"
                >
                  <path d="M24.309 19.338c0 2.758 1.862 4.83 4.634 4.83 2.758 0 4.634-2.072 4.634-4.83s-1.876-4.83-4.634-4.83c-2.772 0-4.634 2.072-4.634 4.83zm8.064 0c0 2.17-1.344 3.794-3.43 3.794-2.1 0-3.43-1.624-3.43-3.794 0-2.184 1.33-3.794 3.43-3.794 2.086 0 3.43 1.61 3.43 3.794zM41.033 24h1.443l-4.229-4.9 3.893-4.438h-1.457l-4.129 4.83v-4.83h-1.162V24h1.162v-3.136l.938-1.064 3.541 4.2z" />
                </svg>
              </button>
            )}
          </div>
          {activeSection === sections.length - 1 && (
            <>
              <div className={s.additionalFields}>
                <label className={s.checkboxLabel}>
                  I would like to receive marketing updates
                  <input
                    type="checkbox"
                    className={s.checkbox}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        marketing: e.target.checked,
                      })
                    }
                  />
                  <span className={s.checkboxIcon} />
                </label>
                <label className={s.checkboxLabel}>
                  <span>
                    I have read and understand the
                    <br />
                    <Link to="/legal" className={s.linkUnderline}>
                      privacy policy
                    </Link>{' '}
                    &{' '}
                    <Link to="/legal" className={s.linkUnderline}>
                      terms of use
                    </Link>
                  </span>
                  <input
                    type="checkbox"
                    className={s.checkbox}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        terms: e.target.checked,
                      })
                    }
                  />
                  <span className={s.checkboxIcon} />
                </label>
                {formError && <p className={s.formError}>{formError}</p>}
                <div className={s.submit}>
                  {sending && <Loading className={s.loading} />}
                  <input type="submit" value="Submit" className={s.submitBtn} />
                </div>
              </div>
            </>
          )}
          <button
            type="button"
            onClick={handleCloseButtonClick}
            className={s.closeBtn}
          >
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17" cy="17" r="17" fill="#fff" />
              <path d="M10 11l14 14m0-14L10 25" stroke="#676767" />
            </svg>
          </button>
          {activeField && (
            <div className={s.activeField}>
              <div className={s.field}>
                <label
                  className={s.label}
                  htmlFor={`activeField_${activeFieldAttr}`}
                >
                  {activeFieldLabel}
                </label>
                <div className={s.fieldInput}>
                  {activeField.placeholderPrefix && (
                    <div className={s.placeholderPrefix}>
                      {activeField.placeholderPrefix}
                    </div>
                  )}
                  {activeField.select ? (
                    <>
                      <select
                        className={classNames(s.input, {
                          [s.inputWithPrefix]: activeField.placeholderPrefix,
                        })}
                        id={`activeField_${activeFieldAttr}`}
                        value={activeFieldValue}
                        onChange={(e) => handleChange(e.target.value)}
                      >
                        {activeField.select.map((opt) => (
                          <option key={opt}>{opt}</option>
                        ))}
                      </select>
                      <svg
                        width="9"
                        height="14"
                        viewBox="0 0 9 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={s.selectIcon}
                      >
                        <path
                          d="M4.358 0l3.295 5.25h-6.59L4.358 0zM4.358 14L1.064 8.75h6.589L4.358 14z"
                          fill="#000"
                        />
                      </svg>
                    </>
                  ) : (
                    <input
                      type={activeField.type || 'text'}
                      className={classNames(s.input, {
                        [s.inputWithPrefix]: activeField.placeholderPrefix,
                      })}
                      ref={activeFieldRef}
                      id={`activeField_${activeFieldAttr}`}
                      placeholder={
                        activeField.placeholder ||
                        activeFieldAttr
                          .replace(/([A-Z])/g, ' $1')
                          .replace(/^./, (str) => str.toUpperCase())
                      }
                      value={activeFieldValue}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  )}
                </div>
                {activeFieldError && (
                  <p className={s.fieldError}>{activeFieldError}</p>
                )}
              </div>
              <div className={s.buttons}>
                <button
                  type="button"
                  className={s.button}
                  onClick={handleCancelActiveField}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="16"
                    viewBox="22.5 11.5 15 16"
                  >
                    <path
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M37 12l-14 7.5L37 27"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  className={s.button}
                  onClick={handleAcceptActiveField}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.167"
                    height="9.66"
                    viewBox="24.309 14.508 18.167 9.66"
                  >
                    <path d="M24.309 19.338c0 2.758 1.862 4.83 4.634 4.83 2.758 0 4.634-2.072 4.634-4.83s-1.876-4.83-4.634-4.83c-2.772 0-4.634 2.072-4.634 4.83zm8.064 0c0 2.17-1.344 3.794-3.43 3.794-2.1 0-3.43-1.624-3.43-3.794 0-2.184 1.33-3.794 3.43-3.794 2.086 0 3.43 1.61 3.43 3.794zM41.033 24h1.443l-4.229-4.9 3.893-4.438h-1.457l-4.129 4.83v-4.83h-1.162V24h1.162v-3.136l.938-1.064 3.541 4.2z" />
                  </svg>
                </button>
              </div>
              <button
                type="button"
                className={s.closeBtn}
                onClick={handleCloseButtonClick}
              >
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="17" cy="17" r="17" fill="#fff" />
                  <path d="M10 11l14 14m0-14L10 25" stroke="#676767" />
                </svg>
              </button>
            </div>
          )}
        </form>
      </div>
      {overlay && (
        <Overlay
          close={() => setOverlay(null)}
          confirmed={() => navigate('/wholesale/application')}
          className={s.overlay}
        >
          {overlay}
        </Overlay>
      )}
    </Layout>
  )
}
