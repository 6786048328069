import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import s from './styles.module.css'

const Overlay = ({ close, children, confirmed, className }) => {
  return (
    <div
      className={classNames(s.container, className)}
      onClick={close}
      role="presentation"
    >
      <div className={s.content}>
        {children.confirm || children}
        {children.confirm && (
          <div className={s.buttons}>
            <button type="button" onClick={confirmed} className={s.button}>
              Close
            </button>
            <button type="button" onClick={close} className={s.button}>
              Continue
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

Overlay.propTypes = {
  close: PropTypes.func,
  children: PropTypes.node,
  confirmed: PropTypes.func,
  className: PropTypes.strig,
}
Overlay.defaultProps = {
  close: () => {},
  children: null,
  confirmed: () => {},
  className: '',
}

export default Overlay
